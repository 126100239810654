import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"
import clsx from "clsx"
import { GatsbyImage } from "gatsby-plugin-image"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import List from "@material-ui/core/List"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import addToSchema from "../services/addToSchema"
import SEO from "../components/SEO"
import BannerSlider from "../components/BannerSlider"
import DynamicBanner from "../components/banners/DynamicBanner"
import Breadcrumbs from "../components/Breadcrumbs"
import Navigation from "../components/Navigation"
import GeneralContact from "../components/contacts/GeneralContact"
import Author from "../components/news/Author"
import MixedCarousel from "../components/carousels/MixedCarousel"

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    padding: theme.spacing(2),
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  groupName: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  groupList: {
    listStylePosition: "outside",
    padding: theme.spacing(0, 2),
    color: theme.palette.aresBlue.main,
    listStyleType: "circle",
  },

  groupItem: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.aresBlue.main}`,

    "&:hover": {
      color: theme.palette.aresBlue.main,
    },
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },

  date: {
    paddingBottom: 10
  }
}))

const ConditionPage = props => {
  const {
    data: { logo, condition, oldConditionItems, recentConditionItems },
    pageContext: { locale, pageSlug, breadcrumbs },
    location,
  } = props

  const classes = useStyles()

  const oldItems = oldConditionItems.edges.map(r => r.node)
  const recentItems = recentConditionItems.edges.map(r => r.node)

  let schema = condition.data.schema_json.text || ""

  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${process.env.GATSBY_BASE_URL}${localizedSlugPrefix(
        condition
      )}/afectiuni/${condition.uid}/`,
    },
    "headline": condition.data.meta_title,
    "image": condition.data.image.url,
    "author": {
      "@type": "Person",
      "name": condition.data?.author?.document?.data?.name?.text ?? 'Echipa Monza Ares',
      "url": condition.data?.author?.document?.uid ? `${process.env.GATSBY_BASE_URL}/echipa/${condition.data?.author?.document?.uid}/` : `${process.env.GATSBY_BASE_URL}${logo.publicURL}`
    },
    "publisher": {
      "@type": "Organization",
      "name": "Monza Ares",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.GATSBY_BASE_URL}${logo.publicURL}`
      }
    },
    "datePublished": condition.data.date
  })

  const ogp = condition.data.ogp_tags || ""
  const meta = {
    title: condition.data.meta_title,
    description: condition.data.meta_description,
    keywords: condition.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={condition.data.noindex}
      />

      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>

            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>

                <Grid item xs={12} md={8}>
                  <Typography
                    component="h1"
                    variant="h1"
                  >
                    {condition.data.title.text}
                  </Typography>
                  <Grid item>
                    <br />
                    <Typography
                      component="span"
                      variant="small"
                      className={classes.date}
                    >
                      {`Publicat: ${format(parseISO(condition.first_publication_date), "PPP", {
                        locale: ro,
                      })} `}
                    </Typography>
                    <br />
                    <Typography
                      component="span"
                      variant="small"
                      className={classes.date}
                    >
                      {`Ultima actualizare: ${format(parseISO(condition.last_publication_date), "PPP", {
                        locale: ro,
                      })} `}
                    </Typography>

                    {condition.data?.author && condition.data?.author?.document && (
                      <>
                        <br />
                        <Author teamMember={condition.data.author.document} variant="small" />
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                  {condition.data.image.gatsbyImageData && (
                    <GatsbyImage
                      className={classes.image}
                      image={condition.data.image.gatsbyImageData}
                      alt={condition.data.title.text}
                    />
                  )}
                </Grid>

              </Grid>
              <Grid item>
                {condition.data.body1 && (
                  <Navigation content={condition.data.body1} />
                )}
                {condition.data.body1 &&
                  condition.data.body1.map(slice => (
                    <div key={shortid.generate()}>
                      {slice.slice_type === "repeatable_image_type" && (
                        <div className="sliceRepeatableImageType">
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        </div>
                      )}

                      {slice.slice_type === "list" && (
                        <Grid
                          container
                          className={classes.sliceListType}
                          direction="column"
                        >
                          <Grid item>
                            <Typography
                              component="div"
                              dangerouslySetInnerHTML={{
                                __html: slice.primary.content.html,
                              }}
                            />
                          </Grid>
                          <Grid item sm={10}>
                            <List>
                              {slice.items.map(item => (
                                <ListItem
                                  alignItems="flex-start"
                                  key={shortid.generate()}
                                  className={classes.listItem}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt="Item Description"
                                      src={
                                        item.list_item_image.gatsbyImageData
                                          .images.fallback.src
                                      }
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.list_item_content}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                      )}

                      {slice.slice_type === "simple" && (
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                      )}

                      {slice.slice_type === "mixed_carousel" && (
                        <>
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                          <MixedCarousel items={slice.items.map(item => item.link.document)} />
                        </>
                      )}

                      {slice.slice_type === "dynamic_banner" && (
                        <div className={classes.dynamicBanner}>
                          <BannerSlider
                            autoplay={
                              (slice.primary.display_duration ?? 5) * 1000
                            }
                          >
                            {slice.items
                              .filter(
                                item =>
                                  item.banner_type === null ||
                                  item.banner_type === "contact" ||
                                  item.banner_type === "promotion" ||
                                  item.banner_type === "consultant"
                              )
                              .map(item => (
                                <div key={shortid.generate()}>
                                  <DynamicBanner
                                    data={item}
                                    location={location}
                                    locale={locale}
                                  />
                                </div>
                              ))}
                          </BannerSlider>
                        </div>
                      )}
                    </div>
                  ))}
              </Grid>

            </Grid>
            <Grid item xs={12} md={4}>
              {recentItems.length > 0 && (
                <div>
                  <Typography component="h4" variant="h6">
                    Afecțiuni mai noi
                  </Typography>

                  <ul className={classes.groupList}>
                    {recentItems.map(item => (
                      <li key={item.id}>
                        <Link
                          className={classes.groupItem}
                          to={`${localizedSlugPrefix(condition)}/${pageSlug}/${item.uid
                            }/`}
                        >
                          {item.data.title.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {oldItems.length > 0 && (
                <div>
                  <Typography component="h4" variant="h6">
                    Afecțiuni mai vechi
                  </Typography>

                  <ul className={classes.groupList}>
                    {oldItems.map(item => (
                      <li key={item.id}>
                        <Link
                          className={classes.groupItem}
                          to={`${localizedSlugPrefix(condition)}/${pageSlug}/${item.uid
                            }/`}
                        >
                          {item.data.title.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                <GeneralContact location={location} locale={locale} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default ConditionPage

export const query = graphql`
  query PrismicConditionBySlug($slug: String!, $locale: String!, $date: Date!) {
    logo: file(base: {eq: "logo_blue.png"}) {   
      publicURL    
    }

    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    condition: prismicCondition(uid: { eq: $slug }, lang: { eq: $locale }) {
      lang
      id
      first_publication_date
      last_publication_date
      data {
        date
        author {
          document {
            ... on PrismicTeamMember {
              uid
              lang
              data {
                name {
                  text
                }
                competence
                specialisations {
                  specialisation: specialization {
                    document {
                      ... on PrismicSpecialisation {
                        id
                        uid
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        image {
          url
          gatsbyImageData(layout: CONSTRAINED, width: 600)
        }
        body1 {
          ... on PrismicConditionDataBody1List {
            id
            slice_type
            items {
              list_item_content
              list_item_image {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicConditionDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicConditionDataBody1RepeatableImageType {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }


          ... on PrismicConditionDataBody1MixedCarousel {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
            items {
              link {
                document {
                  ... on PrismicProcedure {
                    id
                    uid
                    type
                    data {
                      categories {
                        category {
                          document {
                            ... on PrismicProcedureCategory {
                              id
                              uid
                              data {
                                icon {
                                  alt
                                  gatsbyImageData(layout: FIXED, width: 150)
                                }
                              }
                            }
                          }
                        }
                      }
                      body1 {
                        ... on PrismicProcedureDataBody1Simple {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                        ... on PrismicProcedureDataBody1RepeatableImageType {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }               
                      title {
                        text
                      }
                    }
                  }


                  ... on PrismicNews {
                    id
                    type
                    uid
                    first_publication_date
                    data {
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                      title {
                        text
                      }
                    }
                  }



                  ... on PrismicCondition {
                    id
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                    }
                  }



                  ... on PrismicTeamMember {
                    id
                    lang
                    data {
                      name {
                        text
                      }
                      specialisations {
                        specialisation: specialization {
                          document {
                            ... on PrismicSpecialisation {
                              id
                              uid
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      profile_image {
                        alt
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                        url
                      }
                    }
                    uid
                    type
                  }
                }
              }
            }
          }



          ... on PrismicConditionDataBody1DynamicBanner {
            id
            slice_type
            primary {
              display_duration
            }
            items {
              banner_type
              banner_text {
                html
                text
              }
              banner_text_2 {
                html
              }
              phone
              display_phone
              consultant_image {
                gatsbyImageData(layout: FIXED, width: 110)
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
      uid
    }

    oldConditionItems: allPrismicCondition(
      sort: { fields: data___date, order: DESC }
      filter: {
        lang: { eq: $locale }
        data: { date: { lte: $date } }
        uid: { ne: $slug }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date
          }
          uid
        }
      }
    }

    recentConditionItems: allPrismicCondition(
      sort: { fields: data___date, order: DESC }
      filter: {
        lang: { eq: $locale }
        data: { date: { gte: $date } }
        uid: { ne: $slug }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date
          }
          uid
        }
      }
    }
  }
`

ConditionPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    logo: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    oldConditionItems: PropTypes.object.isRequired,
    recentConditionItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
